.__visly_reset {
    padding: 0;
    border: 0;
    outline: none;
    border: none;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
    box-sizing: border-box;
    text-decoration: none;
    border-width: 0px;
    border-style: solid;
    line-height: 1;
    background-color: transparent;
    text-overflow: ellipsis;
}

.__visly_reset::placeholder {
    opacity: 1;
}
