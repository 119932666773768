/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
.__visly_scope_AzHv2NByua_root {
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  height: 96px;
  border-color: #d0d4d9;
  border-style: solid;
  background-color: #edeff2;
  align-items: center;
  border-radius: 2px;
  border-width: 1px;
  padding: 30px;
}
.__visly_scope_AzHv2NByua_root.__visly_default.__visly_state_hover {
  background-color: #fff;
}
.__visly_scope_AzHv2NByua_root.__visly_default.__visly_state_focus {
  border-color: #753bbd;
  background-color: #fff;
  box-shadow: 0 0 0 2px #c7b1e6;
}
.__visly_scope_AzHv2NByua_root .__visly_scope_AzHv2NByua_input::placeholder {
  color: #aaaeb5;
}
.__visly_scope_AzHv2NByua_root .__visly_scope_AzHv2NByua_input {
  border: none;
  color: #4a4c52;
  font-family: Visly Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 26px;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
}
.__visly_scope_AzHv2NByua_root.__visly_default.__visly_state_disabled
  .__visly_scope_AzHv2NByua_input {
  color: #aaaeb5;
}
.__visly_scope_AzHv2NByua_root .__visly_scope_AzHv2NByua_G1kYNxteiQ {
  align-self: stretch;
  width: 16px;
  height: unset;
}
.__visly_scope_AzHv2NByua_root .__visly_scope_AzHv2NByua_2x9zSCdnq6 {
  display: block;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  background-color: #000;
}
