/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
.__visly_scope_9twmSf6vJZ_B3GMqQbie6 {
  width: 1080px;
  height: 740px;
  background-color: #3b366b;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: flex-start;
  justify-content: center;
}
.__visly_scope_9twmSf6vJZ_B3GMqQbie6 .__visly_scope_9twmSf6vJZ_WYqSvHwq9n {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0px;
}
.__visly_scope_9twmSf6vJZ_B3GMqQbie6 .__visly_scope_9twmSf6vJZ_9kw1TdkdF3 {
  align-self: stretch;
  width: 24px;
  height: unset;
}
.__visly_scope_9twmSf6vJZ_B3GMqQbie6 .__visly_scope_9twmSf6vJZ_DaLbFtbHmy {
  overflow: hidden;
  max-width: 700px;
  flex-grow: 1;
  flex-shrink: 1;
  background-color: transparent;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
  padding: 30px;
}
.__visly_scope_9twmSf6vJZ_B3GMqQbie6 .__visly_scope_9twmSf6vJZ_MXvZeJSANT {
  overflow: hidden;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: flex-start;
  justify-content: center;
}
.__visly_scope_9twmSf6vJZ_B3GMqQbie6 .__visly_scope_9twmSf6vJZ_CL61ZqDsmp {
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  color: #c3dafe;
  text-align: center;
  white-space: normal;
  font-family: Visly Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 64px;
  padding: 0 30px;
}
.__visly_scope_9twmSf6vJZ_B3GMqQbie6 .__visly_scope_9twmSf6vJZ_7fBam9B1ai {
  overflow: hidden;
  position: absolute;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: flex-start;
  justify-content: flex-start;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  transform: none;
}
.__visly_scope_9twmSf6vJZ_B3GMqQbie6 .__visly_scope_9twmSf6vJZ_BXyZQ1o9NC {
  display: block;
  box-sizing: border-box;
  width: 300px;
  height: 300px;
  background-color: #ed8937;
}
.__visly_scope_9twmSf6vJZ_B3GMqQbie6 .__visly_scope_9twmSf6vJZ_4rY6GQvaMN {
  overflow: hidden;
  position: absolute;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: flex-start;
  justify-content: flex-start;
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
  transform: translate(0, -100px);
}
.__visly_scope_9twmSf6vJZ_B3GMqQbie6 .__visly_scope_9twmSf6vJZ_VbB9ApfSku {
  display: block;
  box-sizing: border-box;
  width: 200px;
  height: 200px;
  background-color: #ed8937;
}
.__visly_scope_9twmSf6vJZ_B3GMqQbie6 .__visly_scope_9twmSf6vJZ_D1pafZ1DAQ {
  overflow: hidden;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: flex-start;
  justify-content: center;
}
.__visly_scope_9twmSf6vJZ_B3GMqQbie6 .__visly_scope_9twmSf6vJZ_AsNNnL12JS {
  overflow: hidden;
  max-width: 700px;
  flex-grow: 1;
  background-color: transparent;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  padding: 0 30px 30px;
}
.__visly_scope_9twmSf6vJZ_B3GMqQbie6 .__visly_scope_9twmSf6vJZ_7jMX9m9fma,
.__visly_scope_9twmSf6vJZ_B3GMqQbie6 .__visly_scope_9twmSf6vJZ_TWrpGJFfX7 {
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  color: #c3dafe;
  text-align: start;
  white-space: normal;
  font-family: Visly Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  flex-shrink: 1;
}
.__visly_scope_9twmSf6vJZ_B3GMqQbie6 .__visly_scope_9twmSf6vJZ_XUxgy1mj9D {
  overflow: hidden;
  padding-left: 16px;
  padding-right: 0;
  padding-bottom: 0;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: flex-start;
  justify-content: flex-start;
}
.__visly_scope_9twmSf6vJZ_B3GMqQbie6 .__visly_scope_9twmSf6vJZ_37p14rTgb5,
.__visly_scope_9twmSf6vJZ_B3GMqQbie6 .__visly_scope_9twmSf6vJZ_7tbHPDGCob {
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  color: #c3dafe;
  text-align: start;
  white-space: normal;
  font-family: Visly Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  flex-shrink: 1;
}
.__visly_scope_9twmSf6vJZ_B3GMqQbie6 .__visly_scope_9twmSf6vJZ_33L9DsTJR6 {
  overflow: hidden;
  padding-left: 16px;
  padding-right: 0;
  padding-bottom: 0;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: flex-start;
  justify-content: flex-start;
}
.__visly_scope_9twmSf6vJZ_B3GMqQbie6 .__visly_scope_9twmSf6vJZ_2kPXKTbjUM,
.__visly_scope_9twmSf6vJZ_B3GMqQbie6 .__visly_scope_9twmSf6vJZ_RyTM3AdUN2 {
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  color: #c3dafe;
  text-align: start;
  white-space: normal;
  font-family: Visly Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  flex-shrink: 1;
}
.__visly_scope_9twmSf6vJZ_B3GMqQbie6 .__visly_scope_9twmSf6vJZ_CqDcxRPPqF {
  align-self: stretch;
  width: unset;
  height: 10px;
}
.__visly_scope_9twmSf6vJZ_B3GMqQbie6 .__visly_scope_9twmSf6vJZ_GxvN5kmKeB {
  overflow: hidden;
  position: absolute;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;
  align-content: flex-start;
  justify-content: flex-start;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
  transform: translate(18px, -18px);
}
.__visly_scope_9twmSf6vJZ_B3GMqQbie6 .__visly_scope_9twmSf6vJZ_PP2rFc5gvn {
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  color: #c3dafe;
  text-align: start;
  white-space: normal;
  font-family: Visly Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  flex-shrink: 1;
}
.__visly_scope_9twmSf6vJZ_B3GMqQbie6 .__visly_scope_9twmSf6vJZ_3yLJ28pA4W {
  align-self: stretch;
  width: 4px;
  height: unset;
}
