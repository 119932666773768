/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
.__visly_scope_KtRZVu1zch_E5PLsccuzL {
  width: 96px;
  height: 96px;
  background-color: #5a67d8;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: flex-start;
  justify-content: center;
  border-radius: 20000px;
  padding: 0;
}
.__visly_scope_KtRZVu1zch_E5PLsccuzL .__visly_scope_KtRZVu1zch_XNuAjLVdZU {
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  color: #fff;
  text-align: start;
  white-space: normal;
  font-family: Visly Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 46px;
  line-height: 46px;
  flex-shrink: 1;
}
.__visly_scope_KtRZVu1zch_E5PLsccuzL.__visly_status-valid.__visly_state_none {
  background-color: #00c19f;
}
.__visly_scope_KtRZVu1zch_E5PLsccuzL.__visly_status-invalid.__visly_state_none {
  background-color: #e73c3e;
}
