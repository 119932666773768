/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
.__visly_scope_6eGpMYo7hC_Vw1J9VNuYS {
  border-color: transparent;
  border-style: solid;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: flex-start;
  justify-content: flex-start;
  border-width: 0 0 2px;
}
.__visly_scope_6eGpMYo7hC_Vw1J9VNuYS.__visly_default.__visly_state_hover {
  border-color: #c3dafe;
  border-style: solid;
  border-width: 0 0 2px;
}
.__visly_scope_6eGpMYo7hC_Vw1J9VNuYS .__visly_scope_6eGpMYo7hC_NdFZGzZUia {
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  color: #c3dafe;
  text-align: start;
  white-space: normal;
  font-family: Visly Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  flex-shrink: 1;
}
